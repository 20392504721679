import React, {Component} from "react";
import multisport from '../assets/multisport.png';
import metcon from '../assets/metcon.png';
import strez from '../assets/strez.png';

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src={strez} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src={multisport} alt="Logo Images"/>
                    </li>
                    <li>
                        <img src={metcon} alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default BrandTwo;