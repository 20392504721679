import React from 'react'
import Breadcrumb from "../components/Breadcrumb";
import {FiCheck} from "react-icons/fi";

const Pricing = () => {
    const plans = [{
        id: 1,
        name: "DROP IN",
        price: 35,
        description: "PLN - jednorazowy",
        elements: [
            "Jednorazowe wejście na obiekt, tzw. OPEN GYM",
            "Jednorazowy udział w treningu grupowym lub personalnym",
            "Dostęp do szatni"
        ]
    }, {
        id: 2,
        name: "GYM",
        price: 150,
        description: "PLN - samoodnawialny",
        elements: [
            "Brak dostępu do zajęć grupowych",
            "Idealny na treningi personalne",
            "Karnet dla osób trenujących samodzielnie"
        ]
    }, {
        id: 3,
        name: "OPEN",
        price: 220,
        description: "PLN - samoodnawialny",
        elements: [
            "Dostęp do zajęć grupowych",
            "Możliwość treningu indywidualnego"
        ]
    }, {
        id: 4,
        name: "OPEN STUDENT",
        price: 180,
        description: "PLN - samoodnawialny",
        elements: [
            "Dla studentów z ważną legitymacją studencką do 26. r.ż.",
            "Dostęp do zajęć grupowych",
            "Możliwość treningu indywidualnego"
        ]
    }];

    return (<>
            <Breadcrumb title={'Cennik'}/>
            <main className="page-wrapper">
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            {plans.map(plan => (<div key={plan.id} style={{
                                minHeight: "45vh"
                            }} className="col-lg-3 col-md-6 col-12">
                                    <div className="rn-pricing">
                                        <div className="pricing-table-inner" style={{minHeight: '45vh'}}>
                                            <div className="pricing-header">
                                                <h4 className="title">{plan.name}</h4>
                                                <div className="pricing">
                                                    <span className="price">{plan.price}</span>
                                                    <span className="subtitle">{plan.description}</span>
                                                </div>
                                            </div>
                                            <div className="pricing-body" style={{textAlign: "left"}}>
                                                <ul className="list-style--1">
                                                    {plan.elements.map(el => (<li style={{fontSize: 14, fontWeight: 500}} key={el}><FiCheck/> {el}</li>))}
                                                </ul>
                                            </div>
                                            {/*<div className="pricing-footer">*/}
                                            {/*    <a className="rn-btn" href="#pricing">Purchase Now</a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </main>
        </>

    )
}
export default Pricing;