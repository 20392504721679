import React from "react";
import {useTranslation} from "react-i18next";

const ServiceTwo = () => {
    const {t} = useTranslation();

    const services = t('servicesTwo.services', {returnObjects: true});

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <h2 className="title">{t('servicesTwo.title')}</h2>
                        <p style={{textAlign: 'justify'}}>{t('servicesTwo.description')}</p>
                        {/*<div className="service-btn">*/}
                        {/*    <a className="btn-transparent rn-btn-dark" href="/"><span*/}
                        {/*        className="text">{t('servicesTwo.additional')}</span></a>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="col-lg-8 col-12 mt_md--50">
                    <div className="row service-one-wrapper">
                        {services.map((val, i) => {
                            const width = i === services.length - 1 ? 12 : 6;
                            const classNameString = `col-lg-${width} col-md-${width} col-sm-${width} col-12`

                            return (
                                <div className={classNameString} key={i}>
                                    <a href="/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img width={80} height={80} style={{
                                                    marginLeft: -8,
                                                    marginBottom: -16,
                                                }} src={val.icon} alt={val.title} />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p style={{textAlign: 'justify'}}>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ServiceTwo;
