import React from "react";
import Heading from "../components/Heading";
import ServiceTwo from "../components/ServiceTwo";
import About from "../components/About";
import BrandTwo from "../components/BrandTwo";

const MainPage = () => {
    return (
        <React.Fragment>
            <div className="slider-wrapper">
                <Heading/>
            </div>
            <div className="about-area about-position-top pb--120 bg_color--1">
                <About/>
            </div>
            <div className="service-area ptb--80  bg_image bg_image--3">
                <div className="container">
                    <ServiceTwo/>
                </div>
            </div>
            <div className="rn-brand-area brand-separation pb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainPage;