import React from "react";
import {FaFacebookF, FaInstagram} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const SocialShare = [
    {Social: <FaFacebookF/>, link: 'https://www.facebook.com/'},
    {Social: <FaInstagram/>, link: 'https://www.instagram.com/'},
]

const Footer = () => {
    const {t} = useTranslation();

    return (
        <footer className="footer-area">
            <div className="footer-wrapper">
                <div className="row align-items-end row--0">
                    <div className="col-lg-6">
                        <div className="footer-left">
                            <div className="inner">
                                <span>{t('footer.subtitle')}</span>
                                <h2 style={{fontFamily: 'StrezFont'}}>{t('footer.title')}</h2>
                                <a className="rn-button-style--2" href="/contact">
                                    <span>{t('footer.button')}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="footer-right" data-black-overlay="6">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="footer-link">
                                        <h4>{t('footer.contact')}</h4>
                                        <ul className="ft-link">
                                            <li><a href="/contact">{t('footer.line1')}</a></li>
                                            <li><a href="/contact">{t('footer.line2')}</a></li>
                                            <li><a href="/contact">{t('footer.line3')}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                    <div className="footer-link">
                                        <h4>{t('footer.hello')}</h4>
                                        <ul className="ft-link">
                                            <li><a href="/contact">{t('footer.hello1')}</a></li>
                                            <li><a href="/contact">{t('footer.hello2')}</a></li>
                                        </ul>

                                        <div className="social-share-inner">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="copyright-text">
                                        <p>{t('footer.bottom')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;