import React from 'react';
import './scss/style.scss';
import MainPage from './pages/MainPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Header from "./components/Header";
import Footer from "./components/Footer";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {pl} from "./assets/pl";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import {TimetableStatic} from "./pages/TimetableStatic";
import Rent from "./pages/Rent";
import {Buy} from "./pages/Buy";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            pl: pl,
        },
        lng: "pl",
        fallbackLng: "pl",
        interpolation: {escapeValue: false}
    }).then(() => console.log("i18n init!"));

const Root = () => {
    return (
        <BrowserRouter>
            <div className="active-dark">
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
                <Routes>
                    <Route exact path={'/'} element={<MainPage/>}/>
                    <Route exact path={'/rent'} element={<Rent/>}/>
                    <Route exact path={'/buy'} element={<Buy/>}/>
                    <Route exact path={'/timetable'} element={<TimetableStatic/>}/>
                    <Route exact path={'/pricing'} element={<Pricing/>}/>
                    <Route exact path={'/contact'} element={<Contact/>}/>
                </Routes>
                <Footer/>
            </div>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);