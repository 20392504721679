import React from "react";
import about1 from '../assets/about3.jpg'
import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation();
    const services = t('about.services', {returnObjects: true})
    return (
        <React.Fragment>
            <div className="about-wrapper" style={{paddingTop: 32}}>
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src={about1} alt="About Images"/>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{t('about.title')}</h2>
                                    <p style={{textAlign: 'justify'}} className="description">{t('about.description')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="row mt--30 mt_sm--10">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="about-us-list">
                                        <h3 className="title">Zajęcia grupowe</h3>
                                        <p style={{textAlign: 'justify'}}>
                                            W naszej ofercie znajdziesz szereg zajęć grupowych,
                                            prowadzonych przez wykwalifikowanych trenerów.
                                            Zapraszamy Cię serdecznie na nasze zajęcia: treningi Cross (WOD),
                                            kettlebells, weightlifting, powerlifting, gymnastics, kalistenika.
                                            W ofercie mamy również zajęcia dla dzieci Cross KIDS, które odbywają
                                            się we wtorki i czwartki o godzinie 17 oraz 18.
                                        </p>
                                        <p style={{textAlign: 'justify'}}>
                                            A co jeśli jesteś świeżakiem i chcesz zacząć z nami swoją przygodę
                                            ze sportem i dołączyć do naszego świata? Mamy dla Ciebie idealne zajęcia,
                                            na których poznasz podstawowe ruchy funkcjonalne,
                                            nasze wartości oraz cele. Zapisz się na zajęcia INTRO – pierwszy trening w tej grupie jest zupełnie darmowy.
                                        </p>
                                        <a className="rn-button-style--2" href="https://wod.guru/pierwsze-zajecia/wroclaw">
                                            <span>Zapisz się!</span>
                                        </a>
                                    </div>
                                </div>
                                {services.map(service => (
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h3 className="title">{service.title}</h3>
                                            <p style={{textAlign: 'justify'}}>{service.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default About;