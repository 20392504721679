import kb from '../assets/icons/1.svg';
import gs from '../assets/icons/2.svg';
import wl from '../assets/icons/3.svg';
import plift from '../assets/icons/4.svg';
import boks from '../assets/icons/5.svg';
import ks from '../assets/icons/6.svg';
import ba from '../assets/icons/7.svg';
import mob from '../assets/icons/8.svg';
import wod from '../assets/icons/9.svg';

export const pl = {
    translation: {
        header: {
            start: "Start",
            timetable: "Grafik",
            pricing: "Cennik",
            contact: "Kontakt",
            signIn: "Zapisz się!",
            rent: "Wynajem sali",
            buy: "Kup karnet"
        },
        main: "Let’s train together!",
        services: [
            {
                title: 'NASZE MIEJSCE',
                description: 'Idealne miejsce na trening w Twojej najbliższej okolicy. Pomiędzy nadodrzańskimi wałami, a malowniczym kąpieliskiem „Morskie Oko”.',
            },
            {
                title: 'NASI TRENERZY',
                description: 'Będziesz pod opieką najlepszych trenerów we Wrocławiu. Znajdziesz tutaj dodatkowo lekarzy oraz fizjoterapeutów, co sprawia, że możesz czuć się u nas bezpiecznie.',
            },
            {
                title: 'NASZ GRAFIK',
                description: 'Znajdziesz u nas różne zajęcia – każdy znajdzie coś dla siebie.',
            }
        ],
        about: {
            title: 'O nas',
            description: 'Jesteśmy idealnym miejscem zarówno dla osób trenujących sport zawodowo, jak i amatorów. Panuje u nas niesamowicie rodzinna i przyjazna atmosfera co wyróżnia nasz BOX na tle innych znajdujących się we Wrocławiu. Gwarantujemy, że już po pierwszej wizycie będziesz się czuł jako część naszej drużyny. Dzięki temu, uwierzysz, że nie ma barier, których nie możesz pokonać. Nasi trenerzy wprowadzą Cię w świat sportu najlepiej jak to tylko możliwe. Znajdziesz u nas mnóstwo sprzętu oraz miejsca, co sprawi, że nie będziesz narzekać na nudę i rutynowe treningi.',
            services: [
                {
                    title: 'TRENINGI PERSONALNE',
                    description: 'Morskie Oko Health&Performance Wrocław jest siedzibą Polskiego Związku Trenerów Personalnych, co sprawia, że tylko u nas zespół trenerski, składa się z prawdziwych profesjonalistów zarówno trenerów jak i fizjoterapeutów – gotowych do współpracy.',
                },
            ],
        },
        servicesTwo: {
            title: 'Zajęcia',
            description: 'W Morskim Oku trenujemy zarówno sportowców, jak i amatorów. Trenujemy indywidualnie i grupowo. Trenujemy innowacyjnie i z pasją. Gwarantujemy, że z nami wzmocnisz się fizycznie, ale przede wszystkim psychicznie. Uwierzysz, że nie ma barier, nie do pokonania. Nie nauczymy Cię, jak omijać przeszkody, ale jak je pokonywać. Na treningu, w życiu osobistym i zawodowym.',
            additional: 'Sprawdź ofertę treningów personalnych',
            services: [
                {
                    icon: wod,
                    title: 'WOD',
                    description: 'Trening cross, jest to trening o wysokiej intensywności bazujący na ruchach funkcjonalnych. Każde zajęcia są jedyne w swoim rodzaju, niepowtarzalne dzięki czemu będziesz gotowy na nieznane.  Aby w pełni móc korzystać z tych zajęć musisz przejść przez 2 tygodnie zajęć INTRO.'
                },
                {
                    icon: plift,
                    title: 'Powerlifting',
                    description: 'Zajęcia bazujące na treningu siłowym, w tym na dyscyplinach wchodzących w skład trójboju siłowego: martwy ciąg, przysiad, wyciskanie na ławce.'
                },
                {
                    icon:wl,
                    title: 'Weightlifting',
                    description: 'Zajęcia, na których poznasz technikę elementów dwuboju olimpijskiego: rwania i zarzutu. Zajęcia są dla każdego, niezależnie od poziomu zaawansowania. Wszystkie elementy opracujemy od PCV – dopiero później przyjdzie czas na prawdziwe ciężary.'
                },
                {
                    icon: gs,
                    title: 'Gymnastics Skills',
                    description: 'Zajęcia techniczne, na których nauczysz się dzięki odpowiednio dobranej progresji wymagających ruchów gimnastycznych pojawiających się bardzo często na regularnych grupach WOD. Więc jeśli brakuje Ci jakiejś umiejętności gimnastycznej, np. Handstand walk, handstand push up, kipping pull ups, bar muscle up, ring muscle up – to te zajęcia są właśnie dla Ciebie.'
                },
                {
                    icon: mob,
                    title: 'Mobility',
                    description: 'Zajęcia, których głównym celem jest zwiększanie mobilności oraz ruchomości w stawach. Mogą być one uzupełnieniem Twojego treningu lub zupełnie samodzielną jednostką treningową. Zajęcia prowadzone są pod okiem Jacka.'
                },
                {
                    icon: kb,
                    title: 'Kettlebells',
                    description: 'Zajęcia, na których główną częścią jest praca z odważnikiem kulowym – Kettlebell.  Trening prowadzi do poprawy ogólnej sprawności. Zajęcia angażują mocno mięśnie nóg, pośladków oraz pleców i brzucha. Większość ćwiczeń opartych jest na wzorcach funkcjonalnych, przez co wzmocnisz stawy oraz wyrobisz nawyk prawidłowej postawy ciała.'
                },
                {
                    icon: boks,
                    title: 'Boks',
                    description: 'Podczas zajęć koncentrujemy się przede wszystkim na poprawnej technice wykonywania ruchów, przez co możesz liczyć na spalenie ogromnej ilości kalorii. Uczestnicząc w zajęciach poprawisz pewność siebie. Zarówno osoby początkujące oraz bardziej zaawansowane świetnie odnajdą się podczas tego treningu.'
                },
                {
                    icon: ks,
                    title: 'Kalistenika',
                    description: 'Jest to trening ogólnorozwojowy, głównie siłowy z wykorzystaniem ciężaru własnego ciała. Głównym celem treningu kalistenicznego jest kompleksowe wsparcie rozwoju ciała. Każde z ćwiczeń obejmuje wytrenowanie brzucha, ramion, pleców, grzbietu oraz nóg. Na zajęciach ćwiczymy stanie na rękach, uczymy się wykonywać trudne w wykonaniu figury gimnastyczne ale przede wszystkim uczymy się swojego ciała.'
                },
                {
                    icon: ba,
                    title: 'Bulletproof athlete',
                    description: 'Bulletproof athlete to zajęcia dla osób, które chcą zmienić swoje ciało w niezniszczalną maszynę - silną w ruchu, odporną na kontuzje, sprawną na długie lata. \n' +
                        '\n' +
                        'Chcesz skakać wyżej, biegać szybciej, osiągać wyniki jak zawodowi sportowcy, ale bezpiecznie, bez kontuzji?\n' +
                        '\n' +
                        '\n' +
                        'Chcesz przełamać impas treningowy i wejść na nowy poziom atletyzmu? \n' +
                        '\n' +
                        '\n' +
                        'Chcesz jak dziecko relaksować się w przysiadzie, wchodzić na kolana bez trzymanki, wykręcać ciało w zakresach, o których istnieniu zapomniało? \n' +
                        '\n' +
                        '\n' +
                        'Przyjdź na zajęcia Bulletproof Athlete bazujących na systemie treningowym ATG (Athletic Truth Group), który pomaga ludziom na całym świecie odzyskiwać i osiągać kolejne poziomy sprawności.\n' +
                        '\n' +
                        '\n' +
                        'W ATG budujemy od podstaw, od stopy przez łydkę, po kolana, biodra i wyżej. Z każdej strony, wyrównując dysproporcje. Od krótkich po długie zakresy, bez bólu, bez kontuzji, eliminując słabe punkty po drodze. Najpierw wolno, z ciężarem własnego ciała, żeby w końcu przyspieszyć i wystrzelić swój poziom atletyzmu w kosmos!'
                },
            ],
        },
        contact: {
            title: "Skontaktuj się z nami",
            subtitle: "Tutaj jakiś podpis do kontaktu.",
            form: {
                title: "Kontakt",
                subtitle: "Dane kontaktowe opis",
                line1: "tel. 734 427 332",
                line2: "ul. Chopina 27 Wrocław",
                line3: "strezgym@gmail.com",
                line4: "",
            }
        },
        footer: {
            subtitle: 'Zainteresowany?',
            title: 'Sprawdź gdzie nas znaleźć',
            button: 'Kontakt',
            contact: 'Kontakt',
            bottom: 'Strez Gym 2023',
            line1: 'tel. 734 427 332',
            line2: 'ul. Chopina 27 Wrocław',
            line3: 'strezgym@gmail.com',
            hello: 'Godziny otwarcia',
            hello1: 'Pn-Pt - 06:00 - 21:00',
            hello2: 'So-Nd - ogłaszane na naszych profilach społecznościowych w tygodniu ',
        }
    }
};