import React from 'react';
import Breadcrumb from "../components/Breadcrumb";

export const Buy = () => {
    return (
        <React.Fragment>
            <Breadcrumb title={'Kup Karnet'}/>
            <main className="page-wrapper">
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <iframe src="https://wod.guru/kup-karnet/wroclaw" width="100%" height="800px" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}