import Breadcrumb from "../components/Breadcrumb";
import React from "react";
import classroom from '../assets/sala0.jpeg';
import classroom1 from '../assets/sala1.jpeg';

const Rent = () => {
    return (
        <>
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" style={{paddingBottom: 0}}
                 data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Wynajem sali treningowej</h2>
                                <p>Jeśli chcesz zorganizować u nas swoje szkolenie weekendowe lub warsztaty – zapraszamy!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{
                marginTop: 32,
                marginBottom: 32
            }} className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="service-details-inner">
                            <div className="inner">

                                <div className="row sercice-details-content pb--80 align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <div className="thumb">
                                            <img className="w-100" src={classroom1} alt="Service Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="details mt_md--30 mt_sm--30">
                                            <p style={{color: '#d9d9d9'}} className="title">Do wyboru masz dwie
                                                sale:</p>
                                            <ul className="liststyle">
                                                <li style={{color: '#d9d9d9', textAlign: 'justify'}}>część przeznaczona do treningu Cross oraz
                                                    treningu funkcjonalnego, wyposażona w pomosty ciężarowe oraz
                                                    konstrukcje;
                                                </li>
                                                <li style={{color: '#d9d9d9', textAlign: 'justify'}}>część przeznaczona do treningów
                                                    personalnych wyposażona w różnego rodzaju wyciągi, power racki,
                                                    konstrukcje.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row sercice-details-content align-items-center">
                                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                                        <div className="details mt_md--30 mt_sm--30">
                                            <p style={{color: '#d9d9d9', textAlign: 'justify'}}>W sobotę istnieje możliwość wynajęcia sal w
                                                godzinach od 11 do 18, cena
                                                za wynajem to 150PLN netto/godzina*; </p>
                                            <p style={{color: '#d9d9d9', textAlign: 'justify'}}> W niedzielę istnieje możliwość wynajęcia sal
                                                w godzinach od 9 do 18,
                                                cena za wynajem to 125PLN netto/godzina*. </p>
                                            <p style={{color: '#d9d9d9', fontSize: 12, lineHeight: 1.25, textAlign: 'justify'}}>
                                                *Podana cena dotyczy wynajęcia jednej z sal, co oznacza, że druga
                                                dostępna jest dla naszych klubowiczów oraz trenerów – wszystko
                                                oczywiście przy zachowaniu względnej ciszy. Sprzątanie po
                                                przeprowadzonym szkoleniu po stronie wynajmującego. Fajnie jeśli
                                                otrzymalibyśmy jedno miejsce w kursie/ warsztacie ze zniżką 50%.
                                            </p>
                                            <p style={{color: '#d9d9d9', marginTop: 32, fontWeight: '500'}}>Jeśli jesteś
                                                zainteresowany wynajmem skontaktuj się z nami: </p>
                                            <p style={{color: '#d9d9d9', marginTop: 0, marginBottom: 2, fontWeight: '500'}}> @ -
                                                strezgym@gmail.com </p>
                                            <p style={{color: '#d9d9d9', marginTop: 0, marginBottom: 4, fontWeight: '500'}}> Tel. –
                                                734427332 </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 order-1 order-lg-2">
                                        <div className="thumb position-relative">
                                            <img className="w-100" src={classroom} alt="Service Images"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Rent;