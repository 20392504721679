import React from 'react';
import BrandTwo from "../components/BrandTwo";
import {useTranslation} from "react-i18next";
import szymon from '../assets/about4.jpg';

const Contact = () => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" style={{paddingBottom: 0}} data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">{t('contact.title')}</h2>
                                {/*<p>{t('contact.subtitle')}</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.0988568703383!2d17.082868315886454!3d51.125084546404764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe8008fb583c9%3A0xf0d82b70dffccbc2!2sMorskie%20Oko%20-%20Health%20%26%20Performance!5e0!3m2!1spl!2spl!4v1665420262041!5m2!1spl!2spl"
                        width={'100%'}
                        height={400}
                    ></iframe>
                </div>
            </div>
            <div className="rn-contact-page ptb--120 bg_color--1">
                <div className="contact-form--1">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-4 order-2 order-lg-1">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title">{t('contact.form.title')}</h2>
                                    {/*<p className="description">{t('contact.form.subtitle')}</p>*/}
                                </div>
                                <div style={{color: 'white'}} className="form-wrapper">
                                    <p>{t('contact.form.line1')}</p>
                                    <p>{t('contact.form.line2')}</p>
                                    <p>{t('contact.form.line3')}</p>
                                    <p>{t('contact.form.line4')}</p>
                                </div>
                            </div>
                            <div className="col-lg-8 order-1 order-lg-2">
                                <div className="thumbnail mb_md--30 mb_sm--30">
                                    <img src={szymon} alt="Szymon running"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Contact;