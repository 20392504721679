import React from "react";
import Services from "./Services";
import {useTranslation} from "react-i18next";

const Heading = () => {
    const {t} = useTranslation();

    return (
        <div className="slider-activation">
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
                 data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">{t('main')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="service-wrapper service-white">
                        <Services/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Heading;