import React from "react";
import {Link} from 'react-router-dom';
import {FiMenu, FiX} from "react-icons/fi";
import logotype from '../assets/logo-white.png';
import {useTranslation} from "react-i18next";

const Header = () => {
    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    const CLoseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    const {t} = useTranslation();

    return (
        <header className={`header-area formobile-menu header--transparent default-color`}>
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            <img src={logotype} style={{maxHeight: '120px'}} alt={'Morskie Oko Logo'}/>
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ul className="mainmenu">
                            <li><Link to="/">{t('header.start')}</Link></li>
                            <li><Link to="/timetable">{t('header.timetable')}</Link></li>
                            <li><Link to="/buy">{t('header.buy')}</Link></li>
                            <li><Link to="/rent">{t('header.rent')}</Link></li>
                            {/*<li><Link to="/pricing">{t('header.pricing')}</Link></li>*/}
                            <li><Link to="/contact">{t('header.contact')}</Link></li>
                        </ul>
                    </nav>
                    <div className="header-btn">
                        <a className="rn-btn"
                           href="https://wroclaw.wod.guru/user/login">
                            <span>{t('header.signIn')}</span>
                        </a>
                    </div>
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                    </div>
                    <div className="close-menu d-block d-lg-none">
                        <span onClick={CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;