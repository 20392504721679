import React from 'react';
import Breadcrumb from "../components/Breadcrumb";

export const TimetableStatic = () => {
    return (
        <React.Fragment>
            <Breadcrumb title={'Grafik'}/>
            <main className="page-wrapper">
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <iframe src="https://wod.guru/grafik/wroclaw" width="100%" height="1100px" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}